import React, { Component } from 'react';
import { withStyle } from 'styletron-react';

import {
    FlexWrapper,
    Section,
    SectionBlock,
    SectionTitle,
} from './mainStyles';
import { LinkButton } from '../../ui/components/input/ButtonStyles';
import CodeEditor from '../../ui/components/codeEditor/CodeEditor';


const Button = withStyle(LinkButton, ({ $isActive = false }) => ({
    outline: 'none',
    ...($isActive && { color: 'white' }),

    ':hover': {
        color: 'white',
    },
}));

const codeExamples = {
    javascript: "let calculate = (a, b, operation) => {\n  let result;\n  switch (operation) {\n    case 'add':\n      result = a + b;"
        + "\n      break;\n    case 'subtract':\n      result = a - b;\n      break;\n  }\n\n  return result;\n};",
    scss: "$base: #009aff;\n$baseDark: #004d90;\n$text: #fff;\n\nbutton {\n  color: $text;\n  background: $base;\n\n  &.-is-active,\n  &:hover {\n    background: $baseDark;\n  }\n}",
    php: "<?php $name = 'Bob'; ?>\n<body>\n  <div>Hi, I'm <?php echo $name; ?></div>"
        + "\n\n  <?php\n    $x = [1, 2, 3, 4, 5];\n    $a = array_map(function($n) {\n      return 2 * $n;\n    });\n\n    print_r($a);\n  ?>\n</body>",
    swift: "import UIKit\n\nclass ViewController: UIViewController {\n  let step:Float=10\n\n  override func viewDidLoad(){\n    super.viewDidLoad()"
        + "\n\n    let slider = UISlider(frame:CGRect(x: 10, y: 100, width: 300, height: 20))"
        + "\n\n    self.view.addSubview(slider)\n  }\n}",
    markdown: "# Whales \n\nWhales are a widely distributed and diverse group of fully [aquatic] placental [marine mammals]. "
        + "They are an informal grouping within the infraorder Cetacea, usually excluding dolphins and porpoises."
        + "\n\n### List of whale species:\n\n- North Atlantic right whale\n- North Pacific right whale\n- Southern right whale\n...",
};

class RichCodeEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            syntax: 'javascript',
        };
    }

    render() {
        return (
            <Section id="editor" $dark $padded $noMargin>
                <FlexWrapper>
                    <SectionBlock>
                        <SectionTitle $dark $spaced>Beautiful Code Editor</SectionTitle>
                        <p>View and edit your code snippets with a syntax colored code editor.
                            <br />With support for 140 languages, you're basically covered!</p>
                        <p />
                        <p>Check out these examples:</p>
                        <p>
                            <Button
                                onClick={() => this.setState({ syntax: 'javascript' })}
                                $isActive={this.state.syntax === 'javascript'}
                            >JavaScript</Button>
                            <Button
                                onClick={() => this.setState({ syntax: 'markdown' })}
                                $isActive={this.state.syntax === 'markdown'}
                            >Markdown</Button>
                            <Button
                                onClick={() => this.setState({ syntax: 'php' })}
                                $isActive={this.state.syntax === 'php'}
                            >PHP</Button>
                            <Button
                                onClick={() => this.setState({ syntax: 'scss' })}
                                $isActive={this.state.syntax === 'scss'}
                            >SCSS</Button>
                            <Button
                                onClick={() => this.setState({ syntax: 'swift' })}
                                $isActive={this.state.syntax === 'swift'}
                            >Swift</Button>
                        </p>

                        {/*<DesktopOnly>*/}
                        {/*    <small><em>(try the editor on the left)</em></small>*/}
                        {/*</DesktopOnly>*/}
                    </SectionBlock>
                    <SectionBlock $secondPosition>
                        <CodeEditor
                            code={codeExamples[this.state.syntax]}
                            language={this.state.syntax}
                            editable
                            showCopyBtn
                            onCodeChange={() => {}}
                        />
                    </SectionBlock>
                </FlexWrapper>
            </Section>
        );
    }
}

export default RichCodeEditor;
