import React from 'react';

import {
    Main,
    MainCaption,
    MainCaptionBig,
    BR, MainAuth, MainAuthCaption,
} from './mainStyles';
import LoginLinks from './LoginLinks';

export default () => (
    <Main>
        <MainCaption>
            <MainCaptionBig>Organize, Share, Collaborate. </MainCaptionBig>
            Save and organize your code snippets in the cloud.
            <BR />Share and collaborate with your team members.
        </MainCaption>
        <MainAuth>
            <MainAuthCaption>Get started for FREE. Sign in with:</MainAuthCaption>
            <LoginLinks />
        </MainAuth>
    </Main>
);
