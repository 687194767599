import React, { Component } from 'react';
import { styled, withStyle } from 'styletron-react';

import config from '../../config/environments';
import { getTheme } from '../../ui/themes';
import { TrackEvent } from '../../helpers/tracker';

const HeroContainer = styled('div', {
    position: 'relative',
    overflow: 'hidden',
    paddingTop: '20px',

    '@media (max-width: 640px)': {
        marginBottom: '30px',
    },
});

const Video = styled('video', {
    display: 'block',
    position: 'relative',
    zIndex: '5',
    margin: '0 auto',
    filter: 'drop-shadow(0 0 10px rgba(0,0,0,0.5))',
    maxWidth: '92vw',
    borderRadius: '4px',

    '@media (max-width: 767px)': {
        height: 'auto',
    },
});

const Dash = styled('div', {
    position: 'relative',
    height: '50vw',
    width: '120%',
    left: '-10%',
    transform: 'rotateZ(-7deg)',
});

const BackDash = withStyle(Dash, {
    background: getTheme().primaryDark,
    zIndex: '1',
    marginTop: '-33vw',

    '@media (min-width: 768px)': {
        height: '400px',
        marginTop: '-400px'
    },
});
const FrontDash = withStyle(Dash, {
    background: getTheme().primaryTextMuted,
    zIndex: '3',
    marginTop: '-33vw',

    '@media (min-width: 768px)': {
        height: '400px',
        marginTop: '-200px'
    },
});


export default class Hero extends Component {
    constructor(props) {
        super(props);

        this.play = null;
        this.t = null;

        this.onVideoStart = this.onVideoStart.bind(this);
    }

    componentWillUnmount() {
        if (this.t) {
            clearTimeout(this.t);
        }
    }

    onVideoStart() {
        if (!this.play) {
            this.t = setTimeout(() => TrackEvent('video-home', 'play'), 10000);
            this.play = true;
        }
    }

    render() {
        return (
            <HeroContainer>
                <Video
                    muted
                    playsInline
                    autoPlay
                    loop
                    width="960"
                    height="640"
                    onPlay={this.onVideoStart}
                >
                    <source src={`${config.application.cdnUrl}/video/snipit-quick-tour.mp4`} type="video/mp4"/>
                </Video>

                <BackDash/>
                <FrontDash/>
            </HeroContainer>
        );
    }
}
