import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { TrackEvent } from '../helpers/tracker';

import HTMLHeader from '../panels/mainPanel/HTMLHeader';
import Header from './landing/Header';
import Main from './landing/Main';
import MainLogin from './landing/MainLogin';
import Hero from './landing/Hero';
import Features from './landing/Features';
import RichCodeEditor from './landing/RichCodeEditor';
import BrowserExtensions from './landing/BrowserExtensions';
import IDEExtensions from './landing/IDEExtensions';
import Testimonials from './landing/Testimonials';
import Pricing from './landing/Pricing';
import Footer from './landing/Footer';


class Homepage extends Component {
    componentDidMount() {
        TrackEvent('home', 'view');
    }

    render() {
        return (
            <>
                <HTMLHeader description="Powerful code snippets manager designed for dev teams and individuals. Save and organize your code snippets in the cloud. Share and collaborate with your team members." />
                <Header />
                <Main />
                <Hero />
                <Features />
                <RichCodeEditor />{/* -- blue, t / g */}
                {/* Lists & Collections */}
                <IDEExtensions />{/* IDE extensions -- white, g / t */}
                {/* -- blue */}
                <BrowserExtensions />{/* -- white, t / g */}
                <Testimonials />{/* -- light blue, g / t */}
                <Pricing />
                <MainLogin />
                <Footer />
            </>
        );
    }
}

export default withRouter(Homepage);
