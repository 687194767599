import React from 'react';
import { styled } from 'styletron-react';

import config from '../../config/environments';
import { getTheme } from '../../ui/themes';
import {
    Wrapper,
    FlexWrapper,
    Section,
    SectionBlock,
    SectionTitle,
} from './mainStyles';


const Avatar = styled('img', {
    position: 'absolute',
    bottom: '0',
    left: 'calc(25% - 250px)',
    width: '300px',
    height: '300px',
    opacity: '0.1',
    pointerEvents: 'none',
});

const BQuote = styled('blockquote', {
    margin: '0',
    // paddingLeft: '20px',
    fontSize: '18px',
    fontStyle: 'italic',

    '::before': {
        content: '"\u201c"',
        float: 'left',
        marginLeft: '-20px',
        marginTop: '10px',
        lineHeight: '10px',
        fontSize: '32px',
        fontFamily: 'serif',
        color: getTheme().primaryDark,
    },
    '::after': {
        content: '"\u201D"',
        lineHeight: '16px',
        verticalAlign: 'text-bottom',
        fontSize: '32px',
        fontFamily: 'serif',
        color: getTheme().primaryDark,
    },
});

export default () => (
    <Section $padded $primary $relative>
        <Wrapper>
            <SectionTitle $dark $small $center>
                Developers around the world love Snipit
            </SectionTitle>
        </Wrapper>
        <FlexWrapper>
            <SectionBlock $secondPosition $textRight>
                lucas{/* f.*/}<small><em> - Berlin, Germany</em></small>
            </SectionBlock>
            <SectionBlock>
                <BQuote>great tool for developers, with a simple interface and high quality design, and great support team.</BQuote>
            </SectionBlock>
        </FlexWrapper>
        <FlexWrapper $padded>
            <SectionBlock $secondPosition $textRight>
                ovidiu{/* b.*/}<small><em> - Bucharest, Romania</em></small>
            </SectionBlock>
            <SectionBlock>
                <BQuote>exactly what i was missing from my development workflow. thank you!</BQuote>
            </SectionBlock>
        </FlexWrapper>
        <FlexWrapper $padded>
            <SectionBlock $secondPosition $textRight>
                a user<small><em> - London, England</em></small>
            </SectionBlock>
            <SectionBlock>
                <BQuote>This is a great app! I've been looking for a good snippet app for ages.</BQuote>
            </SectionBlock>
        </FlexWrapper>
        <FlexWrapper $padded>
            <SectionBlock $secondPosition $textRight>
                nelson{/* lee miller*/}<small><em> - Lynchburg, VA</em></small>
            </SectionBlock>
            <SectionBlock>
                <BQuote>This is EXACTLY what I have been wanting so bad! I'm so excited!</BQuote>
            </SectionBlock>
        </FlexWrapper>
        {/*<FlexWrapper $padded>
            <SectionBlock $secondPosition $textRight>
                kevin j.<small><em> - San Francisco, CA</em></small>
            </SectionBlock>
            <SectionBlock>
                <BQuote>no more task switching while working. everything i need is right at my fingertips.</BQuote>
            </SectionBlock>
        </FlexWrapper>*/}

        <Avatar src={`${config.application.cdnUrl}/images/icons/avatar.svg`} alt="AVATAR" />
    </Section>
);
