import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';

import Homepage from './Homepage';
import Pricing from './Pricing';
import Features from './Features';
import BrowserExtensions from './Extensions';

import SidePanelContainer from '../panels/sidePanel/SidePanelContainer';
import StyledApp from '../panels/mainPanel/StyledApp';

import '../css/fullHeight.css';


const PublicPanels = Loadable({
    loader: () => import(/* webpackChunkName: "publicPages"*/ '../components/public/indexPanels'),
    loading: () => null,
    modules: ['publicPages'],
});


class Landing extends Component {
    render() {
        return (
            <Switch>
                <Route path="/" exact component={Homepage} />
                <Route path="/features" exact component={Features} />
                <Route path="/pricing" exact component={Pricing} />
                <Route path="/browser-extension" exact render={() => <Redirect to="/extensions" />} />
                <Route path="/extensions" exact component={BrowserExtensions} />
                <Route path="/public" render={() => (
                    <StyledApp>
                        <SidePanelContainer />
                        <PublicPanels customTitle={null} />
                    </StyledApp>
                )} />
                <Route path="/" render={() => <Redirect to="/" />} />
            </Switch>
        );
    }
}

export default withRouter(Landing);
